import { Input } from "antd";
import React from "react";

export function PhoneNumberWithPrefix({
  value,
  onChange,
  prefix = "+998",
  ...props
}) {
  return (
    <Input
      prefix={prefix}
      {...props}
      value={(value && value.replace(prefix, "")) || ""}
      size="large"
      style={{ borderRadius: "2rem" }}
      onChange={(e) => {
        if (e.target.value) {
          onChange(
            prefix + (e.target.value.match(/\d+/)?.[0]?.slice(0, 9) || "")
          );
        } else {
          onChange(prefix);
        }
      }}
    />
  );
}
