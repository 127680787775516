import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tag,
} from "antd";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import initial_filter from "constants/InitialFilter";
import { mainUrl } from "api/mainApiInstence";
import { FormComponent } from "./components/FormComponent";
import { CategoryApi } from "api/CategoryApi";

export default function Category() {
  // Table columns
  const columns = [
    {
      title: t("Name"),
      width: 100,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: t("Status"),
      width: 70,
      dataIndex: "status",
      key: "status",
      render: (record, _) => {
        return (
          <Tag color={record === "active" ? "#87d068" : "#f50"}>
            {t(record)}
          </Tag>
        );
      },
    },
    {
      title: t("Photo"),
      width: 130,
      dataIndex: "photo",
      key: "photo",
      render: (record, _) => {
        return (
          <Image
            src={mainUrl() + "/files/" + record}
            style={{
              width: "200px",
              height: "150px",
              objectFit: "contain",
            }}
          />
        );
      },
    },
    {
      title: t("Photo for site"),
      width: 130,
      dataIndex: "photo_site",
      key: "photo_site",
      render: (record, _) => {
        return (
          <Image
            src={mainUrl() + "/files/" + record}
            style={{
              width: "200px",
              height: "150px",
              objectFit: "contain",
            }}
          />
        );
      },
    },
    {
      title: t("Action"),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (record) => {
        return (
          <div gap={2}>
            <Button
              type="primary"
              shape="round"
              ghost
              size="middle"
              onClick={() => {
                form.setFieldsValue(record);
                setFileList({
                  photo: [
                    {
                      url: mainUrl() + "/files/" + record.photo,
                    },
                  ],
                  photo_site: [
                    {
                      url: mainUrl() + "/files/" + record.photo_site,
                    },
                  ],
                });
                setEdit(true);
              }}
            >
              {t("Edit")}
              <EditOutlined />
            </Button>

            <Popconfirm
              title={t("Are you sure to delete this category?")}
              okText={t("Yes")}
              cancelText={t("No")}
              type="danger"
              shape="round"
              size="middle"
              icon={<DeleteOutlined style={{ color: "red" }} />}
              onConfirm={() => {
                onRemove(record.id);
              }}
            >
              <Button
                type="danger"
                shape="round"
                ghost
                size="middle"
                style={{ marginTop: 10 }}
              >
                <DeleteOutlined style={{ color: "red" }} />
                {t("Delete")}
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  // States
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [filter, setFilter] = useState(initial_filter);
  const [fileList, setFileList] = useState({
    photo: [],
    photo_site: [],
  });
  const [form] = Form.useForm();

  // Get portfolios
  useEffect(() => {
    setLoading(true);
    setBtnLoading(true);
    CategoryApi.get(filter)
      .then((res) => {
        setData(res.categories);
        setBtnLoading(false);
        setLoading(false);
      })
      .catch((err) => {
        MessagesService.error(err.message);
        setLoading(false);
        setBtnLoading(false);
      });
  }, [filter]);

  // Post Pacht Delete course functions
  const onFinish = () => {
    setBtnLoading(true);
    const formData = new FormData();
    formData.append("name", form.getFieldValue().name);
    formData.append("photo", form.getFieldValue().photo.file.originFileObj);
    formData.append(
      "photo_site",
      form.getFieldValue().photo_site.file.originFileObj
    );
    formData.append("status", form.getFieldValue().status);
    CategoryApi.create(formData).then((res) => {
      if (res) {
        setData([...data, res.category]);
        MessagesService.success(t("Category created"));
        setOpen(false);
        form.resetFields();
        setBtnLoading(false);
      }
    });
  };
  const onEdit = () => {
    const formData = new FormData();
    setBtnLoading(true);
    formData.append("name", form.getFieldValue().name);
    formData.append("status", form.getFieldValue().status);
    if (form.getFieldValue().photo?.file?.originFileObj) {
      formData.append("photo", form.getFieldValue().photo.file.originFileObj);
    }

    if (form.getFieldValue().photo_site?.file?.originFileObj) {
      formData.append(
        "photo_site",
        form.getFieldValue().photo_site.file.originFileObj
      );
    }

    CategoryApi.edit(form.getFieldValue().id, formData).then((res) => {
      if (res) {
        MessagesService.success(t("Category updated"));
        setEdit(false);
        setData(
          data.map((item) => {
            if (item.id === res.category.id) {
              return res.category;
            }
            return item;
          })
        );
        form.resetFields();
        setFileList({ photo: [], photo_site: [] });
        setBtnLoading(false);
      }
    });
  };
  const onRemove = (id) => {
    CategoryApi.delete(id).then((res) => {
      if (res) {
        console.log(res);
        setData(data.filter((item) => item.id !== id));
        MessagesService.success(t("Category removed"));
      }
    });
  };

  // Render
  return (
    <>
      <Card>
        <Row className="d-flex justify-content-between align-items-center">
          <Col>
            <h4>{t("Category")}</h4>
          </Col>
          <Col>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => {
                setFileList({ photo: [], photo_site: [] });
                setOpen(true);
                form.resetFields();
              }}
            >
              {t("Create category")}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card>
        <Table
          columns={columns}
          dataSource={data}
          // scroll={{
          //   x: 2000,
          // }}
          loading={loading}
          // onChange={(p, _, sort) => {
          //   const f = {
          //     ...filter,
          //     page: p.current,
          //     limit: p.pageSize,
          //   };
          //   setFilter(f);
          //   fetch(f);
          // }}
          pagination={
            {
              // current: filter.page,
              // total: data?.total,
              // pageSize: filter.limit,
            }
          }
        />
      </Card>
      <Modal
        title={t("Create category")}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        footer={null}
      >
        <FormComponent
          type="create"
          fileList={fileList}
          setFileList={setFileList}
          form={form}
          onFinish={onFinish}
          btnLoading={btnLoading}
        ></FormComponent>
      </Modal>
      <Modal
        title={"Edit category"}
        centered
        open={edit}
        onOk={() => setEdit(false)}
        onCancel={() => setEdit(false)}
        footer={null}
        width={800}
      >
        <FormComponent
          type="edit"
          fileList={fileList}
          setFileList={setFileList}
          form={form}
          onFinish={onEdit}
          btnLoading={btnLoading}
        ></FormComponent>
      </Modal>
    </>
  );
}

export const changeVideoLink = (link) => {
  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }
  document.querySelector(".iFrameElement").src =
    "https://www.youtube.com/embed/" +
    getId(link) +
    "?enablejsapi=1&version=3&playerapiid=ytplayer";
};
