import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  RedoOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Row,
  Table,
  Tag,
  Col,
  Popconfirm,
  Select,
  Switch,
  Upload,
  Spin,
  DatePicker,
} from "antd";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import Loading from "components/shared-components/Loading";
import Search from "antd/lib/input/Search";
import { PromocodeApi } from "api/PromocodeApi";
import ExpandedRow from "./components/ExpandedRow";
import UserModal from "./components/UserModal";
import PromocodeModal from "./components/PromocodeModal";
import { convertExcelToJson, downloadToExcel } from "utils/methods";
import { ApplicationApi } from "api/ApplicationApi";
import moment from "moment";
const { RangePicker } = DatePicker;

const productTypeOptions = [
  { value: "", label: t("all") },
  { label: "Plastinka(1)", value: 1 },
  { label: "Podushka(2)", value: 2 },
];

const productTasteOptions = [
  { value: "", label: t("all") },
  { value: 1, label: "Shirin(1)" },
  { value: 2, label: "Nordon(2)" },
];

const statusOptions = [
  { value: "", label: t("all") },
  { value: "active", label: t("Active") },
  { value: "inactive", label: t("Inactive") },
];

const initial_filter = {
  keyword: null,
  product_type: "",
  product_taste: "",
  status: "",
  startDate: "",
  endDate: "",
  ageFrom: null,
  ageTo: null,
  page: 1,
  limit: 10,
};

export default function Promocodes() {
  const columns = [
    {
      title: t("Product"),
      width: 100,
      dataIndex: "product_name",
      key: "product_name",
      render: (record) => record,
    },
    {
      title: t("User"),
      width: 100,
      dataIndex: "full_name",
      key: "full_name",
      render: (record, _) => {
        return (
          <Button
            size="middle"
            onClick={() =>
              setState({
                ...state,
                content: { user_id: _.user_id, promoId: _.id },
                uIsOpen: true,
              })
            }
          >
            {record ?? t("Add user")}
          </Button>
        );
      },
    },
    {
      title: t("Promocode"),
      width: 100,
      dataIndex: "promocode",
      key: "promocode",
      render: (record) => {
        return record;
      },
    },
    {
      title: t("Status"),
      width: 100,
      dataIndex: "status",
      key: "status",
      render: (record) => (
        <Tag color={`${record === "active" ? "green" : "orange"}`}>
          {t(record === "active" ? "Active" : "Inactive")}
        </Tag>
      ),
    },
    {
      title: t("CreatedAt"),
      width: 100,
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: t("Action"),
      key: "operation",
      width: 120,
      render: (record, _) => {
        return (
          <div className="gap-1">
            <Button
              type="primary"
              shape="round"
              ghost
              size="middle"
              onClick={() => {
                setState({
                  ...state,
                  pIsOpen: true,
                  content: _,
                });
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t("Are you sure to delete this?")}
              okText={t("Yes")}
              cancelText={t("No")}
              type="danger"
              shape="round"
              size="middle"
              icon={<DeleteOutlined style={{ color: "red" }} />}
              onConfirm={() => {
                onRemove(record.id);
              }}
            >
              <Button
                type="danger"
                shape="round"
                ghost
                size="middle"
                style={{ margin: "10px 0 0 10px" }}
              >
                <DeleteOutlined style={{ color: "red" }} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  // States
  const [data, setData] = useState();
  const [eventBot, setEventBot] = useState("");
  const [uploading, setUploading] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [filter, setFilter] = useState(initial_filter);
  const [state, setState] = useState({
    content: null,
    uIsOpen: false,
    pIsOpen: false,
    loading: false,
  });

  async function fetch(filter) {
    setState({ ...state, loading: true });
    try {
      const res = await PromocodeApi.get(filter);
      setData(res);
      setFilter({
        ...filter,
        total: res.total,
        page: res.page,
      });
    } catch (err) {
      MessagesService.error(err.message);
    }
    setState({ ...state, loading: false });
  }

  const donwloadExcel = async () => {
    try {
      const res = await PromocodeApi.getAll(filter);
      const productTypeMap = Object.fromEntries(
        productTypeOptions.map((option) => [option.value, option.label])
      );
      const productTasteMap = Object.fromEntries(
        productTasteOptions.map((option) => [option.value, option.label])
      );
      const promocodes = res.items.map((item) => ({
        ...item,
        product_type: productTypeMap[item.product_type],
        product_taste: productTasteMap[item.product_taste],
      }));
      downloadToExcel(promocodes, "Event data");
    } catch (error) {
      MessagesService.error(t(error.message));
    }
  };

  const onRemove = async (id) => {
    try {
      await PromocodeApi.delete(id);
      setData((prevState) => ({
        ...prevState,
        items: data.items.filter((item) => item.id !== id),
      }));
      MessagesService.success(t("Promocode removed"));
    } catch (error) {
      MessagesService.error(t(error.message));
    }
  };

  const onExpand = (expanded, record) => {
    return setExpandedRows(expanded ? [record.id] : []);
  };

  const expandedRowRender = (record) => {
    return <ExpandedRow record={record} />;
  };

  const onSelect = (value, key) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [key]: value,
    }));
  };

  const getBot = async () => {
    try {
      const bots = await ApplicationApi.getBots();
      if (bots.items.length > 0) {
        const eventBot = bots.items.find((bot) => bot.name === "event_bot");
        setEventBot(eventBot);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeStatus = async () => {
    try {
      const updated = await ApplicationApi.updateBot({
        name: "event_bot",
        status: !eventBot?.status,
      });
      setEventBot(updated[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpload = async (e) => {
    try {
      const jsonData = await convertExcelToJson(e.file);
      setUploading(true);
      const res = await PromocodeApi.create({ promocodes: jsonData });
      setData((prevState) => ({
        ...prevState,
        items: [...res, ...prevState.items],
      }));
    } catch (error) {
      console.error("Error converting Excel to JSON:", error);
    }
    setUploading(false);
  };

  const handleRangePicker = (_, dateStrings) => {
    setFilter({
      ...filter,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    });
  };

  const deletePromocodes = async () => {
    try {
      await PromocodeApi.deletePromocodes(filter);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetch(filter);
    getBot();
  }, []);

  return state.loading ? (
    <Loading />
  ) : (
    <>
      <Card>
        <Row className="d-flex justify-content-between align-items-start">
          <Col>
            <h4>{t("Promocodes")}</h4>
          </Col>
          <Col>
            {eventBot?.status ? (
              <Popconfirm
                title={t("Gumstart aksiya botni o'chirishni hohlaysizmi?")}
                onConfirm={handleChangeStatus}
                okText={t("Yes")}
                cancelText={t("No")}
              >
                <Switch
                  checkedChildren={t("Active")}
                  unCheckedChildren={t("Inactive")}
                  checked={eventBot?.status}
                />
              </Popconfirm>
            ) : (
              <Switch
                onChange={handleChangeStatus}
                checkedChildren={t("Active")}
                unCheckedChildren={t("Inactive")}
                checked={eventBot?.status}
              />
            )}
          </Col>
        </Row>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
          className="d-flex align-items-start mb-2"
        >
          <Col span={4}>
            <label htmlFor="">{t("product_type")}</label>
            <Select
              size="middle"
              style={{ width: "100%" }}
              filterOption={false}
              defaultValue={filter.product_type}
              onSelect={(e) => onSelect(e, "product_type")}
              options={productTypeOptions}
            />
          </Col>
          <Col span={4}>
            <label htmlFor="">{t("product_taste")}</label>
            <Select
              size="middle"
              style={{ width: "100%" }}
              filterOption={false}
              defaultValue={filter.product_taste}
              onSelect={(e) => onSelect(e, "product_taste")}
              options={productTasteOptions}
            />
          </Col>
          <Col span={4}>
            <label htmlFor="">{t("Status")}</label>
            <Select
              size="middle"
              style={{ width: "100%" }}
              filterOption={false}
              defaultValue={filter.status}
              onSelect={(e) => onSelect(e, "status")}
              options={statusOptions}
            />
          </Col>
          <Col span={12}>
            <label htmlFor="" className="opacity-0">
              {t("Search")}
            </label>
            <Search
              placeholder={t("search_promocode")}
              type="text"
              size="middle"
              enterButton={t("Filter")}
              defaultValue={filter.keyword}
              style={{ borderRadius: "2rem", display: "block" }}
              onSearch={(value) => {
                setFilter({ ...filter, keyword: value });
                fetch({ ...filter, keyword: value });
              }}
            />
          </Col>
        </Row>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
          className="d-flex align-items-start"
        >
          <Col span={4}>
            <Select
              size="middle"
              showSearch
              style={{ width: "100%" }}
              placeholder={t("age_from")}
              defaultValue={filter.ageFrom}
              onSelect={(e) => onSelect(e, "ageFrom")}
              options={Array.from({ length: 71 }, (_, i) => ({
                label: i + 10,
                value: i + 10,
              }))}
            />
          </Col>
          <Col span={4}>
            <Select
              size="middle"
              showSearch
              defaultValue={filter.ageTo}
              style={{ width: "100%" }}
              placeholder={t("age_to")}
              onSelect={(e) => onSelect(e, "ageTo")}
              options={Array.from({ length: 71 }, (_, i) => ({
                label: i + 10,
                value: i + 10,
              }))}
            />
          </Col>
          <Col span={8}>
            <RangePicker
              className="w-100"
              placeholder={[t("start_date"), t("end_date")]}
              defaultValue={
                filter.startDate
                  ? [moment(filter.startDate), moment(filter.endDate)]
                  : false
              }
              format="YYYY-MM-DD"
              onChange={handleRangePicker}
            />
          </Col>
          <Col span={5}>
            <Popconfirm
              title={t(
                "Are you sure you want to delete promocodes for the selected date range?"
              )}
              okText={t("Yes")}
              cancelText={t("No")}
              type="danger"
              shape="round"
              size="middle"
              icon={<DeleteOutlined style={{ color: "red" }} />}
              onConfirm={deletePromocodes}
            >
              <Button className="w-100" size="middle" type="danger" ghost>
                {t("delete_promocodes")}
              </Button>
            </Popconfirm>
          </Col>
          <Col span={3}>
            <Button
              className="w-100"
              size="middle"
              type="primary"
              // icon={<RedoOutlined />}
              onClick={() => fetch(initial_filter)}
            >
              {t("Refresh")}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card>
        <Row className="justify-content-between">
          <Button onClick={donwloadExcel}>
            <DownloadOutlined />
            {t("download_excel")}
          </Button>
          <Row className="mb-2">
            <Upload
              loa
              showUploadList={false}
              accept=".xlsx, .xls"
              className="ant-alert-icon"
              beforeUpload={() => false}
              onChange={handleUpload}
            >
              <Button disabled={uploading}>
                {uploading ? (
                  <Spin size="small" />
                ) : (
                  <>
                    <UploadOutlined /> {t("upload_excel")}
                  </>
                )}
              </Button>
            </Upload>
            <Button onClick={() => setState({ ...state, pIsOpen: true })}>
              <PlusOutlined />
              {t("Add")}
            </Button>
          </Row>
        </Row>

        <Table
          columns={columns}
          loading={state.loading}
          rowKey={(record) => record.id}
          expandable={{
            onExpand: onExpand,
            expandedRowKeys: expandedRows,
            expandedRowRender: expandedRowRender,
          }}
          onChange={(p, _) => {
            const f = {
              ...filter,
              page: p.current,
              limit: p.pageSize,
            };
            setFilter(f);
            fetch(f);
          }}
          pagination={{
            current: filter?.page,
            total: data?.total,
            pageSize: filter?.limit,
          }}
          dataSource={data?.items}
        />
      </Card>
      {state.uIsOpen && (
        <UserModal open={state} setOpen={setState} setData={setData} />
      )}
      {state.pIsOpen && (
        <PromocodeModal open={state} setOpen={setState} setData={setData} />
      )}
    </>
  );
}
