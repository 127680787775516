import { FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row } from "antd";
import { t } from "i18n";
import React, { useMemo } from "react";

export function FilterView({ filter, onSubmit, fields }) {
  const initial = useMemo(() => filter, []);
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      initialValues={initial}
      onFinish={onSubmit}
      layout="vertical"
    >
      <Row gutter={8}>
        {fields.map((field, i) => (
          <Col xs={12} sm={8} md={8} lg={8} key={i}>
            <Form.Item name={field.name} label={field.label}>
              <field.InputComponent
                size="large"
                type={field?.type}
                style={{ borderRadius: "2rem" }}
                placeholder={field.label}
                {...field.inputProps}
              />
            </Form.Item>
          </Col>
        ))}
        <Col span={5} className="ml-auto">
          <Form.Item label={t("Filter")}>
            <div className="d-flex">
              <Button
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                style={{ marginRight: 5 }}
                icon={<FilterOutlined />}
              >
                {t("Filter")}
              </Button>
              <Button
                block
                size="large"
                htmlType="submit"
                shape="round"
                icon={<ReloadOutlined />}
                onClick={() => form.resetFields("")}
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
