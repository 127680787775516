import { RedoOutlined, RetweetOutlined } from "@ant-design/icons";
import { Button, Card, Form, Modal, Row, Select, Table, Col } from "antd";
import OrderStatus, { StatusSwitcher } from "constants/OrderStatus";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import dayjs from "dayjs";
import initial_filter from "constants/InitialFilter";
import { ApplicationApi } from "api/ApplicationApi";
import Loading from "components/shared-components/Loading";
import { formatCurrency } from "utils/formatCurrency";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import sales from "assets/images/sales.jpeg";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import { APP_PREFIX_PATH } from "config/AppConfig";

export default function Application() {
  // Table columns
  const columns = [
    {
      title: t("Order id"),
      width: 60,
      dataIndex: "id",
      key: "id",
      render: (record, _) => {
        return (
          <Link to={`${APP_PREFIX_PATH}/user/${_.user.id}`}>{record}</Link>
        );
      },
    },
    {
      title: t("Market name"),
      width: 100,
      dataIndex: "market_name",
      key: "market_name",
      render: (record, _) => {
        return (
          <Button
            type="link"
            onClick={() =>
              setActiveUser({
                visible: true,
                user: _.user,
              })
            }
          >
            {_.user.market_name ? _.user.market_name : "N/A"}
          </Button>
        );
      },
    },
    {
      title: t("Phone number"),
      width: 100,
      dataIndex: "user",
      key: "user",
      render: (record) => {
        return record.phone_number;
      },
    },
    {
      title: t("Status"),
      width: 100,
      dataIndex: "status",
      key: "status",
      render: (record) => StatusSwitcher(record),
      filters: Object.entries(OrderStatus).map(([value, text]) => ({
        value,
        text,
      })),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: t("CreatedAt"),
      width: 100,
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (record) => {
        return dayjs(record).format("DD.MM.YYYY, HH:MM");
      },
    },
    {
      title: t("Action"),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (record) => {
        return (
          <div>
            <Button
              ghost
              size="middle"
              type="primary"
              onClick={() => {
                setOpen(true);
                form.setFieldsValue(record);
              }}
            >
              <RetweetOutlined />
            </Button>

            <Button
              ghost
              size="middle"
              type="primary"
              onClick={() => {
                onSendSales(record?.id);
              }}
              style={{
                marginTop: 5,
                borderColor: "green",
                color: "green",
                display: "flex",
                alignItems: "center",
                gap: 5,
              }}
            >
              <img src={sales} alt="Sales logo" width={15} height={15} />
              <span>Send</span>
            </Button>
          </div>
        );
      },
    },
  ];
  // States
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(initial_filter);
  const [form] = Form.useForm();
  const [activeUser, setActiveUser] = useState({
    visible: false,
    user: null,
  });
  // Get application types
  useEffect(() => {
    fetch(filter);
  }, []);

  function fetch(filter) {
    setLoading(true);
    ApplicationApi.get(filter)
      .then((res) => {
        setData(res);
        setFilter({
          ...filter,
          total: res.total,
          page: res.page,
        });
        setLoading(false);
      })
      .catch((err) => {
        MessagesService.error(err.message);
        setLoading(false);
      });
  }

  const changeStatus = () => {
    ApplicationApi.edit(form.getFieldValue().id, {
      status: form.getFieldValue().status,
    })
      .then((res) => {
        setData({
          ...data,
          items: data.items.map((item) => {
            if (item.id === form.getFieldValue().id) {
              return res.item;
            }
            return item;
          }),
        });
        MessagesService.success(t("Status changed"));
        setOpen(false);
        form.setFieldValue();
      })
      .catch((err) => {
        MessagesService.error(t("Error"));
      });
  };

  const onSendSales = (id) => {
    ApplicationApi.sendSales(id)
      .then((res) => {
        console.log(res);
        if (res?.res_user?.error) {
          res?.res_user?.result?.failed?.forEach((item) => {
            MessagesService.error(item.error);
          });
          return;
        }
        MessagesService.success(t("Sales sent"));
        setOpen(false);
        form.setFieldValue();
      })
      .catch((err) => {
        MessagesService.error(t("Error"));
      });
  };

  // Render
  return loading ? (
    <Loading />
  ) : (
    <>
      <Card>
        <Row className="d-flex justify-content-between align-items-start">
          <Col>
            <h4>{t("Orders")}</h4>
          </Col>
          <Col>
            <Button onClick={() => fetch(filter)}>
              <RedoOutlined />
              <span>{t("Refresh")}</span>
            </Button>
          </Col>
        </Row>
        <Search
          placeholder={t("Search by phone number or market name")}
          type="text"
          size="large"
          enterButton={t("Search")}
          defaultValue={filter.keyword}
          onSearch={(value) => {
            setFilter({ ...filter, keyword: value });
            fetch({ ...filter, keyword: value });
          }}
          style={{ borderRadius: "2rem", width: "50%", display: "block" }}
        />
      </Card>
      <Card>
        <Table
          columns={columns}
          loading={loading}
          rowKey={(record) => record.id}
          expandable={{
            expandedRowRender: (record) => {
              const columns = [
                {
                  title: t("Product name"),
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: t("Category name"),
                  dataIndex: "category",
                  key: "category",
                  render: (record, _) => {
                    return _?.product?.category?.name;
                  },
                },
                {
                  title: t("Price"),
                  dataIndex: "price",
                  key: "price",
                  render: (record) => {
                    return formatCurrency(record);
                  },
                },
                {
                  title: t("Count"),
                  dataIndex: "count",
                  key: "count",
                },
              ];
              return (
                <>
                  <Table
                    columns={columns}
                    pagination={false}
                    dataSource={record?.order_items}
                  />
                  <h5 className="d-flex justify-content-end mt-2">
                    <b>{t("Total")}:&nbsp;</b>
                    {formatCurrency(record?.total)}
                  </h5>
                </>
              );
            },
          }}
          onChange={(p, _, sort) => {
            const f = {
              ...filter,
              page: p.current,
              limit: p.pageSize,
            };
            setFilter(f);
            fetch(f);
          }}
          pagination={{
            current: filter.page,
            total: data?.total,
            pageSize: filter.limit,
          }}
          dataSource={data?.items}
        />
      </Card>
      <Modal
        title={t("Change status application")}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
        width={400}
      >
        <Form form={form} name={"Change status"} onFinish={changeStatus}>
          <Form.Item
            label={t("Status")}
            name="status"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            rules={[
              {
                required: true,
                message: "Please input status!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t("Status")}
              size="large"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={Object.entries(OrderStatus).map(([value, label]) => {
                return { value, label };
              })}
            />
          </Form.Item>
          <Form.Item className="d-flex justify-content-end">
            <Button type="primary" size="large" htmlType="submit">
              {t("Change")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t("User data")}
        centered
        open={activeUser.visible}
        onOk={() =>
          setActiveUser({
            visible: false,
            user: null,
          })
        }
        onCancel={() =>
          setActiveUser({
            visible: false,
            user: null,
          })
        }
        footer={null}
        width={600}
      >
        <Row>
          <Col span={12}>
            <b>{t("Market name")}: </b>
            {activeUser?.user?.market_name}
          </Col>
          <Col span={12}>
            <b>{t("Phone number")}: </b>
            {activeUser?.user?.phone_number}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <b>{t("Address")}: </b>
            {activeUser?.user?.address}
          </Col>
          <Col span={12}>
            <b>{t("Telegram username")}: </b>
            <a
              href={`https://t.me/${activeUser?.user?.username}`}
              target="_blank"
              rel="noreferrer"
            >
              {activeUser?.user?.username}
            </a>
          </Col>
        </Row>
        <Row>
          <YMaps>
            <Map
              defaultState={{
                center: [41.2995, 69.2401],
                zoom: 9,
              }}
              width={600}
              height={400}
            >
              <Placemark
                geometry={[activeUser?.user?.lat, activeUser?.user?.long]}
              />
            </Map>
          </YMaps>
        </Row>
      </Modal>
    </>
  );
}
