import { DatePicker, Input } from "antd";
import { ApplicationApi } from "api/ApplicationApi";
import { FilterView } from "components/FilterView";
import dayjs from "dayjs";
import { t } from "i18n";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "utils/formatCurrency";
const initial_filter = {
  time: moment(new Date(new Date().setHours(0, 0, 0, 0))),
};

export default function Dashboard() {
  const [data, setData] = useState();
  const [filter, setFilter] = useState(initial_filter);
  useEffect(() => {
    ApplicationApi.getStat({
      date: new Date(filter.time),
    })
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [filter]);

  return (
    <div>
      <FilterView
        fields={[
          {
            name: "time",
            label: t("Date"),
            type: "number",
            span: 4,
            InputComponent: DatePicker,
          },
        ]}
        filter={filter}
        onSubmit={(values) => {
          setFilter(values);
        }}
      />
      <div
        style={{
          background: "#61C2D0",
          color: "#fff",
          width: "500px",
        }}
      >
        <div
          style={{
            background: "black",
            color: "#fff",
            textAlign: "center",
            padding: 10,
          }}
        >
          {t("The total amount of applications for ") +
            dayjs(filter.date).format("D MMM")}
        </div>

        <div
          style={{
            textAlign: "center",
            color: "#fff",
            padding: "40px 0",
          }}
        >
          <h2
            style={{
              fontSize: 32,
              color: "#fff",
            }}
          >
            {formatCurrency(data?.total)}
          </h2>
          <span>
            {t("Sale on ")}
            {dayjs(filter.date).format("D MMM")}
          </span>
        </div>
      </div>
    </div>
  );
}
