import React, { useState } from "react";
import { Layout, Menu, Button, Row, Col, Select } from "antd";
import { LogoutOutlined, MenuFoldOutlined } from "@ant-design/icons";
import AppViews from "../../views/app-views";
import { generateNavigationConfig } from "../../config/NavigationConfig";
import Icon from "../../components/util-components/Icon";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { changeLanguage, getLang, t } from "../../i18n";
import { signOut } from "../../redux/actions/Auth";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const { Header, Content, Footer, Sider } = Layout;

export const AppLayout = () => {
  const history = useHistory();
  const [isMenu, setIsMenu] = useState(true);
  const { user } = useSelector((state) => state.auth);
  console.log(user);
  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          background: "#000",
        }}
        width={isMenu ? 250 : 70}
      >
        <div
          style={{
            background: "#fff",
            width: "100%",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              height: isMenu ? 50 : 10,
              marginLeft: 10,
              marginTop: 10,
              marginBottom: 10,
            }}
          />
        </div>

        <Menu
          theme="dark"
          mode="inline"
          style={{ background: "#000" }}
          defaultOpenKeys={["sub1"]}
        >
          {generateNavigationConfig().map((item) => {
            return (
              user?.role === item?.role && (
                <Menu.Item
                  key={item.key}
                  style={
                    {
                      // background: "#F3B892",
                      // hover edit bg
                    }
                  }
                >
                  {item.icon ? <Icon type={item.icon} /> : null}
                  {isMenu && <span>{item.title}</span>}
                  <Link to={item.path} />
                </Menu.Item>
              )
            );
          })}
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={isMenu ? { marginLeft: 250 } : { marginLeft: 70 }}
      >
        <Header style={{ background: "#fff", position: "relative" }}>
          <Button
            type="primary"
            onClick={() => setIsMenu(!isMenu)}
            style={{ position: "absolute", zIndex: 10, top: 0, left: 0 }}
          >
            <MenuFoldOutlined />
          </Button>
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Select
                onChange={changeLanguage}
                defaultValue={getLang()}
                style={{
                  color: "#000",
                  padding: 5,
                }}
                options={[
                  {
                    value: "uz",
                    label: "O'zbekcha",
                  },
                  {
                    value: "ru",
                    label: "Русский",
                  },
                  {
                    value: "en",
                    label: "English",
                  },
                ]}
              />
              <Button
                key={5}
                onClick={() => {
                  signOut();
                  history.push("/auth/login");
                }}
              >
                <LogoutOutlined style={{ marginRight: 5 }} />
                <span className="font-weight-normal">{t("Exit")}</span>
              </Button>
            </Col>
          </Row>
        </Header>
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <AppViews />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Gumstar.uz ©{new Date().getFullYear()} Created by <b>Noortech</b>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
